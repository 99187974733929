<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'result'"
              :total="dataTotal"
              :filter-path="'tssManagement/changeRequestFilter'"
              :actions="true"
              @selectedItems="saveSelectedItems"
            >
              <!-- :export-path="'unpaidLeaves/export'" -->
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        v-if="field.request_status"
                        @click="changeStatus(field)"
                      >
                        <template v-if="field.request_status.status === 'Open'">
                          <i
                            class="me-50"
                            data-feather="archive"
                          /> Archive
                        </template>
                        <template v-else>
                          <i
                            class="me-50"
                            data-feather="book-open"
                          /> Open
                        </template>
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showContact(field.user)">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> Contact information
                      </b-dropdown-item>
                      <b-dropdown-item @click="showAnswer(field)">
                        <i
                          class="me-50"
                          data-feather="send"
                        /> Answer
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="$router.push({name: field.route, params: { id: field.origenable_id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="researcher"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event, role: 'researcher' }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <p><strong>Date trip begin</strong></p>
                <div class="mb-1">
                  <label
                    for="start_date"
                    class="form-label"
                  >Start</label>
                  <date-picker
                    v-model="filters['start_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'start_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="end_date"
                    class="form-label"
                  >End</label>
                  <date-picker
                    v-model="filters['start_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'start_date_to')"
                  />
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.archive"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Archived request</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction2"
                              v-model="actions.open"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction2"
                            >Open request</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    href="#"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
      <ItemInfoSheet :table-id="answerInfoSheet">
        <template #info-sheet-title>
          Answer
        </template>
        <template
          #info-sheet
        >
          <div class="offcanvas-body offcanvas-body--view">
            This action will send an e-mail to the ICREA
            <div class="my-1">
              <label
                for=""
                class="form-label"
              >Message</label>
              <quill-editor
                ref="quillMessage"
                v-model="message"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              v-if="!loading"
              type="button"
              class="btn btn-primary mb-1 d-grid w-100"
              @click="sendAnswer"
            >
              Send
            </button>
            <button
              v-if="loading"
              class="btn btn-primary mb-1 d-grid w-100"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Sending...</span>
            </button>
          </div>
        </template>
      </ItemInfoSheet>
      <ItemInfoSheet :table-id="contactInfoSheet">
        <template #info-sheet-title>
          View contact information
        </template>
        <template
          v-if="selectedContact"
          #info-sheet
        >
          <div class="offcanvas-body offcanvas-body--view">
            <div class="mb-1 offcanvas-body--view--title">
              <p>Name<br>
                <strong>{{ selectedContact.user_name }}</strong></p>
            </div>
            <div
              v-if="selectedContact.university"
              class="mb-1"
            >
              <p>University<br>
                <strong>{{ selectedContact.university.name }}</strong>
              </p>
            </div>
            <hr>
            <div
              v-if="selectedContact.department"
              class="mb-1"
            >
              <p>Department<br>
                <strong>{{ selectedContact.department.name }}</strong></p>
            </div>
            <div
              v-if="selectedContact.phone"
              class="mb-1"
            >
              <p>Phone<br>
                <strong>{{ selectedContact.phone }}</strong></p>
            </div>
            <div
              v-if="selectedContact.mobile_phone"
              class="mb-1"
            >
              <p>Mobile phone<br>
                <strong>{{ selectedContact.mobile_phone }}</strong></p>
            </div>
            <div
              v-if="selectedContact.email"
              class="mb-1"
            >
              <p>Email<br>
                <strong>{{ selectedContact.email }}</strong></p>
            </div>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
    ItemInfoSheet,
  },
  data() {
    return {
      loading: false,
      filters: [],
      selectedItems: [],
      actions: {
        checkInsurance: false,
        unCheckInsurance: false,
        checkTss: false,
        unCheckTss: false,
        archive: false,
        open: false,
      },
      selectedContact: null,
      selectedTrip: null,
      contactInfoSheet: 1,
      answerInfoSheet: 2,
      message: '',
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'Date',
          checked: true,
          order: 2,
        },
        {
          name: 'Trip begin date',
          checked: true,
          order: 6,
        },
        {
          name: 'Type',
          checked: true,
          order: 5,
        },
        {
          name: 'Comment',
          checked: true,
          order: 7,
        },
        {
          name: 'Answer',
          checked: true,
          order: 8,
        },
        {
          name: 'Status',
          checked: true,
          order: 9,
        },
      ],
      sortMapping: {
        Researcher: 'user.user_name',
        Date: 'request_date',
        'Trip begin date': 'begin_date',
        Type: 'type',
        Comment: 'comment',
        Answer: 'answer',
        Country: 'country',
        Status: 'request_status.status_table',
      },
      title: 'Change request',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'tssManagement/changeRequests',
      dataTotal: 'tssManagement/changeRequestsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      countries: 'citizenships/items',
      filtersTotal: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'change-requests')
    await this.$store.dispatch('tssManagement/changeRequestFilter', this.filtersTotal)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'change-requests',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async sendAnswer() {
      if (this.$refs.quillMessage.quill.getLength() - 1 > 0) {
        Vue.swal({
          title: 'This action will send an e-mail to the ICREA',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            this.loading = true
            await this.$store.dispatch('tssManagement/sendAnswer', {
              id: this.selectedTrip.id,
              data: { message: this.message },
            }).then(resp => {
              if (resp.status == 200) {
                this.loading = false
                this.message = ''
                this.selectedTrip = null
                Vue.swal('Answer sent', '', 'success')
                this.$store.dispatch('modals/toggleInfoItemSheet', false)
                this.$store.dispatch('tssManagement/changeRequestFilter', this.filtersTotal)
              } else {
                this.loading = false
                Vue.swal('Error sendind request', `${resp.message ? resp.message : ''}`, 'error')
              }
            })
          }
        })
      } else {
        Vue.swal('There is not meesage to send', '', 'warning')
      }
    },
    showAnswer(item) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.answerInfoSheet)
      this.selectedTrip = item
    },
    showContact(contact) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.contactInfoSheet)
      this.selectedContact = contact
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    applyActions() {
      if(this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('tssManagement/applyActions', { actions: this.actions, itemIds }).then(() => {
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
              this.$toastr.success('', 'Actions applied successfully!')
              this.$store.dispatch('tssManagement/changeRequestFilter', this.filtersTotal)
              this.actions = {
                checkInsurance: false,
                unCheckInsurance: false,
                checkTss: false,
                unCheckTss: false,
                archive: false,
                open: false,
              }
            })
          } else if (this.selectedItems.length === 0) {
            this.$toastr.error('', 'No items selected!')
          }
        })
      } else {
        Vue.swal('No items selected', '', 'warning')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.open) {
        str += '<p class="text-success">Open requests</p>'
      }

      if (this.actions.archive) {
        str += '<p class="text-success">Archive requests</p>'
      }

      return str
    },
    async changeStatus(item) {
      console.log(item)
      await this.$store.dispatch('tssManagement/setStatus', {
        id: item.id,
        data: { type: item.request_status.status === 'Open' ? 'archived' : 'open' },
      }).then(() => {
        this.$toastr.success('', 'Actions applied successfully!')
        this.$store.dispatch('tssManagement/changeRequestFilter', this.filtersTotal)
      })
    },
  },
}
</script>
